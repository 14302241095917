/* You can add global styles to this file, and also import other style files */

// regular style toast
@import '~ngx-toastr/toastr';


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import "~bootstrap/dist/css/bootstrap.min.css";

html, body {
    
    overflow-x: hidden;
    overflow-y: auto;
}

.container{
    padding-right: 8.5rem !important;
    padding-left: 11.5rem !important;
}


.imgBackground{
    height: 100vh;
    position: absolute;
    width: 1502px;
    height: 1345px;
    left: 473px;
    top: -324px;
    z-index: -1;
}




.join-us{
    width: 466px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 66px;
    color: #000000;
}




.phoneBg{
    margin-top: 0%;
}


.label2{
    width: 453px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
   
}

.label2 p{
    width: 453px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    /* Gray 1 */
    color: #333333;
}

.orange{
    color: #FFCC00;
}

.navbar-right{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.nav-link{
    font-family: 'Avenir';
    font-style: normal;
    line-height: 27px;;
    text-align: center;
}

.logo{
    width: 100px;
    height: 100px;
    margin-top: 0.3rem;
    margin-left: 15rem;
}

.nav{
    padding: 2.5rem;
    margin-right: 8rem;
}

.nav-item{
    margin-right: 4rem;
}

.nav-account {
    width: 10.25rem;
    height: 2.5625rem;
    left: 1060px;
    top: 41px;
    /* Grey/White */
    background: #FFFFFF;
    border-radius: 50px;
}

.button1{
    background-color: #FFCC00;
    color: #000000;
}

.button1 :hover{
    background-color: #FFFFFF !important;
    color: #FFCC00 !important;
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.button1 :disabled{
    background-color: #FBFAFA !important;
    color: #000000 !important;
}

.input-container {
    padding-bottom: 1em;
    /* width: 250px; */
    height: 45px;
}

.left-inner-addon {
    position: relative;
}
.left-inner-addon input {
    padding-left: 45px !important;
}

.left-inner-addon i {
    position: absolute;
    padding: 12px 12px;
    pointer-events: none;
}

.left-inner-addon img {
    position: absolute;
    padding: 10px 10px;
    pointer-events: none;
}

.left-addon-search i {
    position: relative;
    padding: 9px 9px;
    pointer-events: none;
}

.right-inner-addon {
    position: relative;
}
.right-inner-addon input {
    padding-right: 35px !important;
}
.right-inner-addon i {
    position: absolute;
    right: 0px;
    padding: 12px 12px;
    pointer-events: none;
}

.right-inner-addon img {
    position: absolute;
    right: 0px;
    padding: 10px 10px;
    pointer-events: none;
}

.inner-addon {
    position: relative;
}

.inner-addon-s {
    position: relative;
}

/* style icon */

.inner-addon .fa {
    position: absolute;
    padding: 16px;
    pointer-events: none;
}

.inner-addon-s .fa {
    position: absolute;
    padding: 10px;
    pointer-events: none;
}

.inner-addon img {
    position: absolute;
    padding: 16px;
    pointer-events: none;
}

.inner-addon-s img {
    position: absolute;
    padding: 10px;
    pointer-events: none;
}

.left-addon img {
    left: 0px;
}

.right-addon img {
    right: 0px;
}

/* aligicon */

.left-addon .fa {
    left: 0px;
}

.right-addon .fa {
    right: 0px;
}

/* add padding  */
.left-addon input {
    padding-left: 40px;
    background-color: #f3f5f9;
}

.right-addon input {
    padding-right: 40px;
}


.recompense{
    width: 305px;
    height: 166px;
    left: 227px;
    top: 4026px;
    background: #C4C4C4;
    border-radius: 15px;
}

.recompense img{
    // height: 100%;
    // width: 100%;
    // position: relative;
    // border-radius: 15px;
    // z-index: 0;
}

.recompense .recTitle {
    position: relative;
    width: 305px;
    height: 45px;
    top: 60%;
    z-index: 1;
    /* Grey/White */
    background: #FFFFFF;
    opacity: 0.9;
    border-radius: 5px;

    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
}

.paddingList{
    padding-right: 10.5rem !important;
    padding-left: 10.5rem !important;
}

.textTitle{
    /* Green/25 */
    border-radius: 20px;
    position: relative;
}

.textTitle span {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    color: #000000;
}

.textTitle p{ 
    margin-bottom: 0;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    color: #000000;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.showAll{
    width: 10.5625rem !important;
    border: none;
    background: #FFCC00;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
}

.showAll p{
    margin-bottom: 0;
    font-family: 'Avenir';
    font-style: normal;
    font-size: 20px !important;
}


.rdv{
    background: #F9F9F9;
    border-radius: 25px;
    margin-left:auto;
    margin-right:auto;
    display:block;
    margin-top: 9rem;
}

.form-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .form-switch i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 46px;
    height: 26px;
    background-color: rgba(248, 213, 137, 0.1);
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
  }
  .form-switch i::before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: rgba(248, 213, 137, 0.1);
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
  }
  .form-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: rgba(255, 201, 83, 1);
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
  }
  .form-switch:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
  }
  .form-switch:active input:checked + i::after { transform: translate3d(16px, 2px, 0); }
  .form-switch input { display: none; }
  .form-switch input:checked + i { background-color: rgba(255, 201, 83, 0.2); }
  .form-switch input:checked + i::before { transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }
  .form-switch input:checked + i::after { transform: translate3d(22px, 2px, 0); }
