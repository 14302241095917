@font-face {
    font-family: "monster_bold";
    src: url("./fontname/Montserrat-Bold.ttf");
}
@font-face {
    font-family: "monster_regular";
    src: url("./fontname/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "caveat_bold";
    src: url("./fontname/Caveat-Bold.ttf");
}